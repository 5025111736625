.store_info_div {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    display: block;
    justify-content: center;
    width: fit-content;
}

.store_name_div {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Rajdhani', sans-serif;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 5px;
    text-align: center;
}

.storePage_description_div {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Rajdhani', sans-serif;
    font-size: 19px;
    font-weight: 600;
    color: #A5A6A6;
    align-items: center;
    letter-spacing: -.01em;
    margin-bottom: 5px;
    text-align: center;
}

.storePage_orderingtypes_div {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Rajdhani', sans-serif;
    font-size: 19px;
    font-weight: 600;
    color: #A5A6A6;
    align-items: center;
    letter-spacing: -.01em;
    margin-bottom: 5px;
    text-align: center;
}

.store_firstBtn {
    width: 30%;
    align-self: center;
    margin-right: auto;
}

.store_secondBtn {
    width: 30%;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
}

.store_thirdBtn {
    width: 30%;
    align-self: center;
    margin-left: auto;
}

.store_info_btns {
    display: flex;
    margin-bottom: 10px;
    text-align: center;
}

#store_info {
    max-width: 600px;
    padding: 4px 25px;
    margin: 0 auto;
}

.store_info_btn_span {
    margin-left: auto;
    margin-right: auto;
    font-family: 'Rajdhani', sans-serif;
    line-height: 17px;
    font-weight: 400;
    color: #1a1824;

}

.items_categories_search_wrapper {
    width: 100%;
    position: sticky;
    background: white;
    top: -10px;
    display: flex;
    text-align: center;
    justify-content: center;
    padding-top: 1px;
    z-index: 100;
    padding-bottom: 5px;
}

.items_categories_search_field {
    width: 100%;
    border-bottom: 1px solid #efefef;
}

.Btn {
    width: 100% !important;
    text-transform: none !important;
    background: #ecfaf6 !important;
    padding: 5px 40px 5px 16px !important;
}

.btn_icon {
    margin-left: 5px;
}

@media only screen and (max-width: 510px) {
    .Btn {
        width: 100% !important;
        text-transform: none !important;
        background: #ecfaf6 !important;
        padding: 5px 0px 5px 0px !important;
    }
}

@media only screen and (max-width: 350px) {
    .store_info_btn_span {
        margin-left: auto;
        margin-right: auto;
        font-family: 'Rajdhani', sans-serif;
        line-height: 17px;
        font-weight: 600;
        font-size: 10px;
        color: #1a1824;

    }

    .css-1d6wzja-MuiButton-startIcon {
        margin-right: 4px !important;
    }
}

.cate_list_header {
    font-family: 'Rajdhani', sans-serif;
    font-size: 25px;
    font-weight: 700;
}

#input-with-icon-adornment {
    font-family: 'Rajdhani', sans-serif;
    font-size: 15px;
    font-weight: 700;
}

.cate_item_header {

    font-family: 'Rajdhani', sans-serif;
    font-size: 25px;
    font-weight: 600;
}

.cate_item_wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    padding-top: 8px;

}

#cate_item_center {
    border-bottom: lightgrey solid 1px !important;
}

.cate_item_description {
    display: block;
    font-size: 14px;
    color: #A5A6A6;
    align-items: center;
    letter-spacing: -.01em;
    margin-top: 5px;
}

.cate_item {
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 15px;
    padding-right: 0;
    border-left: black solid 3px;
}

.listitem_cate_props_div {
    align-self: center;
    margin-right: auto;
    margin-left: 10px;
}

.cate_item_price {
    margin-left: auto;
    padding-right: 25px;
    text-align: center;
}
.item_price_from{
    font-family: 'Rajdhani', sans-serif;
    font-size: 14px;
    color: #A5A6A6;
    letter-spacing: -.01em;
    font-weight: 800;
}
.item_price{
    font-family: 'Rajdhani', sans-serif;
    font-size: 20px;
    color: #A5A6A6;
    letter-spacing: -.01em;
    font-weight: 900;
}
.inBasket_span{
    font-family: 'Rajdhani', sans-serif;
    font-size: 16px;
    color: rgba(198, 43, 43, 0.6);
    letter-spacing: -.01em;
    font-weight: 900;
    padding-left: 5px;
}
.listitem_count_inBasket{
    display: flex;
    text-align: center;
}