#item_topbar_id{

}
.item_topbar{
    width: 100%;
    height: 150px;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 999;
    background-size: cover;
    background-repeat: no-repeat;
}

#item_header_img_id{

}

.item_header_img{
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -10;

}

.item_topbarWrapper{
    height: 100%;
    padding: 0px 20px 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.item_topLeft{
    display: flex;
    text-align: start;
    height: 100%;
}

.item_topMiddle{
    align-self: center;
    font-family: 'Rajdhani', sans-serif;
    font-size: 30px;
    font-weight: 500;
    color: #31313a;
    text-align: left;
    line-height: 56px;
    margin-left: auto;
    margin-right: auto;
}

.item_topRight{
    display: flex;
    text-align: start;
    height: 100%;
}

.nave_icon{
    background: rgba(255, 255, 255, 0.6) !important;;
    margin-top: 3px;
}