.page--side_page__background {
    background-color: white !important;
}
.page--content_page__background {
    background-color: white !important;
}

.side_button {
    background-color: #f3f4f4 !important;
    margin-right: 7px !important;
}