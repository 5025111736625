.modal_msg {
    font-family: 'Rajdhani', sans-serif;
    font-size: 18px;
    color: rgb(233, 168, 168);
    letter-spacing: -.01em;
    font-weight: 500;
    padding-left: 5px;
    margin-bottom: 20px;
}

.modal_btns {
    display: flex;
    text-align: center;
    justify-content: space-around;
}

.first_btn {
    background: rgba(87, 167, 87, 0.6) !important;
    font-family: 'Rajdhani', sans-serif !important;
    font-size: 14px !important;
    letter-spacing: -.01em !important;
    font-weight: 500 !important;
}

.second_btn {
    background: rgb(233, 168, 168, 0.6) !important;
    font-family: 'Rajdhani', sans-serif !important;
    font-size: 14px !important;
    letter-spacing: -.01em !important;
    font-weight: 500 !important;
}

.modal_container {

    display: flex;
    background: rgb(32, 31, 24, 0.8);
    height: 150px;
    text-align: center;
    justify-content: center;
}

.modal_wrapper {
    height: fit-content;
    align-self: center;
}