.item_content {
    max-width: 600px;
    padding: 4px 25px;
    margin: 0 auto;
}

.item_name_div {
    margin-top: 15px;
    width: fit-content;
    font-family: 'Rajdhani', sans-serif;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 5px;
    text-align: center;
}

.item_options_literal {
    margin-top: 10px;
    width: fit-content;
    font-family: 'Rajdhani', sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
    text-align: center;
}

.extras_literal {
    margin-top: 10px;
    width: fit-content;
    font-family: 'Rajdhani', sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
    text-align: center;
}

.item_extras {
    margin-top: 10px;
}

.item_content {
    height: calc(100vh - 205px);
    overflow: scroll;
}

.option_value_div {
    width: fit-content;
    font-family: 'Rajdhani', sans-serif;
    font-size: 16px;
    font-weight: 500;
}

.extra_value_div {
    width: fit-content;
    font-family: 'Rajdhani', sans-serif;
    font-size: 16px;
    font-weight: 500;
}

.extra_price_div {
    width: fit-content;
    font-family: 'Rajdhani', sans-serif;
    font-size: 16px;
    font-weight: 700;
}

.checkout_btn_div {
    display: flex;
    text-align: center;
    justify-content: center;
    width: 100%;
    position: fixed;
    bottom: 5px;
    left: 0px;
}

.checkout_btn {
    width: 90% !important;
    background-color: rgb(10, 131, 5) !important;
}

.add_to_basket_for{
    font-family: 'Rajdhani', sans-serif;
    font-size: 16px;
    font-weight: 700;
}
.item_variant_price{
    font-family: 'Rajdhani', sans-serif;
    font-size: 18px;
    font-weight: 700;
}