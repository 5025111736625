.options_topbar{
    width: 100%;
    height: 50px;
    background-color: white;
    z-index: 999;
}

.options_topbarWrapper{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.options_topLeft{
    display: flex;
    align-items: center;
    text-align: center;
}
.options_topMiddle{
    align-self: center;
    font-family: 'Rajdhani', sans-serif;
    font-size: 18px;
    font-weight: 550;
    color: #31313a;
    text-align: left;
    line-height: 56px;
    margin-left: auto;
    margin-right: auto;
}

.options_topRight{
    display: flex;
    align-items: center;
    text-align: center;
}
.options_nave_icon{
    background: rgba(19, 209, 60, 0.7);
}