.basket_topbar{
    width: 100%;
    height: 50px;
    background-color: rgb(236, 250, 246);
    z-index: 999;
}

.basket_topbarWrapper{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 40px 0px 20px;
}

.basket_topLeft{
    display: flex;
    align-items: center;
    text-align: center;
}
.basket_topMiddle{
    align-self: center;
    font-family: 'Rajdhani', sans-serif;
    font-size: 25px;
    font-weight: 700;
    color: #31313a;
    text-align: left;
    line-height: 56px;
    margin-left: auto;
    margin-right: auto;
}

.basket_topRight{
    display: flex;
    align-items: center;
    text-align: center;
}
.basket_nave_icon{
    background: rgba(255, 255, 255, 0.6) !important;;
    margin-top: 3px;
}