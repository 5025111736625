.store_topbar {
    width: 100%;
    height: auto;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 999;
    background-size: cover;
    background-repeat: no-repeat;
}

.store_header_img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -10;
}

.store_topbarWrapper {
    height: 100%;
    padding: 0px 40px 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.store_topRight {
    display: flex;
    text-align: start;
    height: 100%;
}

.store_topLeft {
    display: flex;
    text-align: start;
    height: 100%;
}

.store_topMiddle {
    align-self: center;
    font-family: 'Rajdhani', sans-serif;
    font-size: 30px;
    font-weight: 500;
    color: #31313a;
    text-align: left;
    line-height: 56px;
    margin-left: auto;
    margin-right: auto;
}

.nave_icon {
    height: fit-content;
}

.store_logo {
    display: block;
    position: absolute;
    left: calc(50% - 60px);
    top: 180px;
}
#stor_topbar_name{
    font-family: 'Rajdhani', sans-serif;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 5px;
}
.nave_icon{
    background: rgba(255, 255, 255, 0.6) !important;
    margin-top: 3px !important;
}